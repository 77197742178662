<div class="project-item" style="background-image: url('{{projectItem.smallImage}}')">
  <div class="project-item-image">
    <div class="item-overlay">
      <a (click)="projectModal.show()" target="_blank">
        <div class="item head">
          <h3>{{projectItem.title}}</h3>
          <p>{{projectItem.description}}</p>
        </div>
        <div class="item footer">
          <div class="technologies-wrapper">
            <ul class="technologies-list">
              <li *ngFor="let technology of projectItem.technologies; let isLast = last">{{technology }}{{!isLast ? ',&nbsp;' : ''}}</li>
            </ul>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>


<div style="overflow-y: auto"  mdbModal #projectModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-full-height modal-right" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="projectModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h3  id="myModalLabel" class="modal-title w-100">{{projectItem.title}}</h3>
      </div>
      <div class="modal-body">
        <div class="description pb-30">
          <p><i class="far fa-file-alt"></i>{{projectItem.description}}</p>
          <div class="technologies-wrapper">
            <span><i class="fas fa-code"></i>Technologies: </span>
            <ul class="technologies-list">
              <li *ngFor="let technology of projectItem.technologies; let isLast = last">{{technology }}{{!isLast ? ',&nbsp;' : ''}}</li>
            </ul>
          </div>

          <a href="{{projectItem.projectLink}}" target="_blank">View project live</a>
        </div>

        <img src="{{projectItem.image}}" class="image" alt="Project image">
      </div>
<!--      <div class="modal-footer">-->
<!--        <button type="button" mdbBtn  class="button" aria-label="Close" (click)="projectModal.hide()" mdbWavesEffect>Close</button>-->
<!--      </div>-->
    </div>
  </div>
</div>

