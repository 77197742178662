import {Component, Input, OnInit} from '@angular/core';
import {Projects} from '../projects-section/projects';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @Input()
  public projectItem: Projects;

  constructor() { }

  ngOnInit(): void {
  }

}
