import { Component, OnInit } from '@angular/core';
import {Contact} from './contact';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public contactItems: Contact[] = [];

  constructor() { }

  ngOnInit(): void {

    const facebook = new Contact();
    facebook.styleClass = 'facebook-item';
    facebook.name = 'facebook';
    facebook.contactLink = 'https://www.facebook.com/artira.ferati';
    facebook.icon = 'fab fa-facebook-f';

    const instagram = new Contact();
    instagram.styleClass = 'instagram-item';
    instagram.name = 'instagram';
    instagram.contactLink = 'https://www.instagram.com/artiraferati/?hl=en';
    instagram.icon = 'fab fa-instagram';

    const linkedin = new Contact();
    linkedin.styleClass = 'linkedin-item';
    linkedin.name = 'linkedin';
    linkedin.contactLink = 'https://www.linkedin.com/in/artira-ferati/';
    linkedin.icon = 'fab fa-linkedin-in';

    const github = new Contact();
    github.styleClass = 'github-item';
    github.name = 'github';
    github.contactLink = 'https://github.com/ArtiraFerati';
    github.icon = 'fab fa-github';

    this.contactItems.push(facebook);
    this.contactItems.push(instagram);
    this.contactItems.push(linkedin);
    this.contactItems.push(github);


  }

}
