<nav class="navbar  navbar-expand">
  <div class="container">
    <a class="navbar-brand" href="">
      <img src="../../assets/images/artira.png" class="img-fluid">
    </a>
    <div class="justify-content-end" id="navbarRightAlignExample">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#skills-section">Skills</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#projects-section">Projects</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact-section">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
