<div class="projects-section py-80" id="projects-section">
  <div class="container">
    <div class="section-title text-center">
      <h2>Projects</h2>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" *ngFor="let item of projectsItems">
        <app-project [projectItem]=item></app-project>
      </div>
    </div>
  </div>
</div>
