import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  fileUrl;

  constructor(private sanitizer: DomSanitizer) { }

  public skills: string[] = ["HTML", "CSS, SCSS, SASS", "JavaScript", "jQuery", "Angular", "Wordpress"];

  ngOnInit(): void {

    const data = '../../assets/Artira-Ferati-CV.pdf';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

  }

}
