<section class="skills-section pb-30" id="skills-section">
  <div class="container">
    <div class="section-title text-center">
      <h2>Skills</h2>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
        <div class="img-wrapper">
          <img src="../../assets/images/vector-creator.png" class="img-fluid" alt="Image">
        </div>

      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
        <div class="content">
          <p>I strive to build immersive and beautiful web applications through carefully crafted code and user-centric design. I have rich experience in website development and customization.<br>
            Also I am good at</p>
          <ul>
            <li *ngFor="let skill of skills">
              <i class="fas fa-code"></i>{{skill}}
            </li>
          </ul>
          <div class="py-30">
            <a href="../../assets/Artira-Ferati-CV.pdf" class="button" download="Artira'sCV.pdf">Download CV</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

