<section class="header">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="header-content">
          <h1> I’m <span>Artira Ferati</span> </h1>
          <p>Your creative web, mobile and desktop development partner.</p>
        </div>
      </div>
    </div>
  </div>
</section>
