<footer class="py-30 text-center">
  <div class="container">
    <div class="social-buttons">
      <ul>
        <li *ngFor="let contactItem of contactItems" class="{{contactItem.styleClass}}">
          <a class="footer-btn {{contactItem.name}}" href="{{contactItem.contactLink}}}" target="_blank">
            <i class="{{contactItem.icon}}"></i>
          </a>
        </li>
      </ul>
    </div>

    <div class="copyright">
      <p>Designed and built with <i class="fas fa-heart"></i> by Artira Ferati © 2021</p>
    </div>
  </div>
</footer>





