<div class="contact-section py-80 text-center" id="contact-section">
  <div class="container">
      <h2>Wanna get in touch or talk about a project?</h2>

    <div class="mt-25">
      <a class="button" href="mailto:artiraferati@gmail.com">Send me a message</a>
    </div>

  </div>
</div>
