import { Component, OnInit } from '@angular/core';
import {Projects} from './projects';

@Component({
  selector: 'app-projects-section',
  templateUrl: './projects-section.component.html',
  styleUrls: ['./projects-section.component.scss']
})
export class ProjectsSectionComponent implements OnInit {

  public projectsItems: Projects[] = [];

  constructor() { }

  ngOnInit(): void {

    const fahrschule  = new Projects();
    fahrschule.title = 'Fahrschule';
    fahrschule.description = 'Driving school website that offers user registration and exam scheduling.';
    fahrschule.image = '../assets/images/fahrschule-bexeotech-1.png';
    fahrschule.smallImage = '../assets/images/fahrschule-bexeotech-small.png';
    fahrschule.projectLink = 'https://fahrschule.bexeotech.com/de';
    fahrschule.technologies = ["HTML", "CSS", "JavaScript", "jQuery"];

    const barAuto  = new Projects();
    barAuto.title = 'Bar Auto';
    barAuto.description = 'Car purchasing and selling platform that includes multiple advanced forms to filter out available cars for sale.';
    barAuto.image = '../assets/images/bar-auto-1.png';
    barAuto.smallImage = '../assets/images/bar-auto-small.png';
    barAuto.projectLink = 'https://barautoankauf.ch/de';
    barAuto.technologies = ["HTML", "CSS", "JavaScript", "jQuery"];

    const noshArchitekten = new Projects();
    noshArchitekten.title = 'Nosh Architekten';
    noshArchitekten.description = 'Architecture Firm static website.';
    noshArchitekten.image = '../assets/images/nosh-1.png';
    noshArchitekten.smallImage = '../assets/images/nosh-small.png';
    noshArchitekten.projectLink = 'https://nosh.businessdev.ch';
    noshArchitekten.technologies = ["HTML", "CSS", " JavaScript", "jQuery"];

    const luftAtlas = new Projects();
    luftAtlas.title = 'Luft Atlas';
    luftAtlas.description = 'Ventilation and air conditioning technology production firm static website.';
    luftAtlas.image = '../assets/images/luftatlas-ch-1.png';
    luftAtlas.smallImage = '../assets/images/luftatlas-ch-small.png';
    luftAtlas.projectLink = 'https://www.luftatlas.ch/de';
    luftAtlas.technologies = ["HTML", "CSS", "JavaScript", "jQuery"];

    const qDrive = new Projects();
    qDrive.title = 'Q-Drive Fahrschule Winterthur';
    qDrive.description = 'Architecture Firm static website.';
    qDrive.image = '../assets/images/q-drive-1.png';
    qDrive.smallImage = '../assets/images/q-drive-small.png';
    qDrive.projectLink = 'https://www.q-drive.ch/de';
    qDrive.technologies = ["HTML", "CSS", "JavaScript", "jQuery"];

    const bexeoSupport = new Projects();
    bexeoSupport.title = 'Bexeo Support';
    bexeoSupport.description = 'Support Center Platform offering ticket submission and status checking.';
    bexeoSupport.image = '../assets/images/support-bexeo-1.png';
    bexeoSupport.smallImage = '../assets/images/support-bexeo-small.png';
    bexeoSupport.projectLink = 'https://support.bexeo.com';
    bexeoSupport.technologies = ["HTML", "CSS", "JavaScript", "PHP","jQuery"];

    const bexeoLanding = new Projects();
    bexeoLanding.title = 'Bexeo Landing Page';
    bexeoLanding.description = 'Bexeo single page website';
    bexeoLanding.image = '../assets/images/bexeo-landing-1.png';
    bexeoLanding.smallImage = '../assets/images/bexeo-landing-small.png';
    bexeoLanding.projectLink = 'https://www.vip-webdesign.ch/';
    bexeoLanding.technologies = ["HTML", "CSS", "JavaScript", "jQuery"];

    const portfolio = new Projects();
    portfolio.title = 'Artira\'s Portfolio';
    portfolio.description = 'Artira\'s personal portfolio website.';
    portfolio.image = '../assets/images/artira-portfolio-1.png';
    portfolio.smallImage = '../assets/images/artira-portfolio-small.png';
    portfolio.projectLink = 'https://www.artiraferati.com/';
    portfolio.technologies = ["HTML", "CSS", "Angular"];


    this.projectsItems.push(fahrschule);
    this.projectsItems.push(barAuto);
    this.projectsItems.push(noshArchitekten);
    this.projectsItems.push(luftAtlas);
    this.projectsItems.push(qDrive);
    this.projectsItems.push(bexeoSupport);
    this.projectsItems.push(bexeoLanding);
    this.projectsItems.push(portfolio);

  }

}
